<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card border-danger">
          <div class="card-body">
            Status : 
          </div>
        </div>
        <div class="card border-primary">
          <div class="card-header bg-primary">
            <h3 class="card-title">Data Pendaftaran berdasarkan Status</h3>
          </div>
          <div class="card-body">
            <ul id="tabs" class="nav nav-tabs">
              <li v-for="gel in gels" :key="gel.id" :class="'nav-item'">
                <a href="" :data-target="'#' + gel.name" data-toggle="tab" :class="'nav-link small text-uppercase ' + (gel.name == 'Satu' ? 'active' :'')">Gelombang {{ gel.name }}</a>
              </li>
            </ul>
            <br>
            <div id="tabsContent" class="tab-content">
              <div v-for="gel in gels" :key="gel.id" 
                :id="gel.name" :class="'tab-pane fade ' + (gel.name == 'Satu' ? 'active show' :'')">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th rowspan="2" class="align-middle" width="32%">Unit</th>
                      <th colspan="3" width="17%">Total</th>
                      <th colspan="3" width="17%">Baru</th>
                      <th colspan="3" width="17%">Aktif</th>
                    </tr>
                    <tr>
                      <th width="5%">L</th>
                      <th width="5%">P</th>
                      <th class="bg-cyan" width="7%">Total</th>
                      <th width="5%">L</th>
                      <th width="5%">P</th>
                      <th class="bg-cyan" width="7%">Total</th>
                      <th width="5%">L</th>
                      <th width="5%">P</th>
                      <th class="bg-cyan" width="7%">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!units.length">
                      <th colspan="11" class="text-center">No Data</th>
                    </tr>
                    <tr v-for="unit in units" :key="unit.id">
                      <td class="text-center">{{ unit.name }}</td>
                      <td class="text-center">{{ totals.status[1][gel.name][unit.name] + totals.status[3][gel.name][unit.name] }}</td>
                      <td class="text-center">{{ totals.status[2][gel.name][unit.name] + totals.status[4][gel.name][unit.name] }}</td>
                      <th class="bg-cyan">{{ totals.status[1][gel.name][unit.name] + totals.status[2][gel.name][unit.name] + totals.status[3][gel.name][unit.name] + totals.status[4][gel.name][unit.name] }}</th>
                      <td class="text-center">{{ totals.status[1][gel.name][unit.name] }}</td>
                      <td class="text-center">{{ totals.status[2][gel.name][unit.name] }}</td>
                      <th class="bg-cyan">{{ totals.status[1][gel.name][unit.name] + totals.status[2][gel.name][unit.name] }}</th>
                      <td class="text-center">{{ totals.status[3][gel.name][unit.name] }}</td>
                      <td class="text-center">{{ totals.status[4][gel.name][unit.name] }}</td>
                      <th class="bg-cyan">{{ totals.status[3][gel.name][unit.name] + totals.status[4][gel.name][unit.name] }}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card border-success">
          <div class="card-header bg-success">
            <h3 class="card-title">Data Pendaftaran berdasarkan Kategori</h3>
          </div>
          <div class="card-body">
            <ul id="tabs" class="nav nav-tabs">
              <li v-for="gel in gels" :key="gel.id" :class="'nav-item'">
                <a href="" :data-target="'#k' + gel.name" data-toggle="tab" :class="'nav-link small text-uppercase ' + (gel.name == 'Satu' ? 'active' :'')">Gelombang {{ gel.name }}</a>
              </li>
            </ul>
            <br>
            <div id="tabsContent" class="tab-content">
              <div v-for="gel in gels" :key="gel.id" 
                :id="'k'+gel.name" :class="'tab-pane fade ' + (gel.name == 'Satu' ? 'active show' :'')">
                <table class="table table-bordered table-hover">
                  <col width="250" />
                  <col width="100" />
                  <thead>
                    <tr>
                      <th rowspan="2" class="align-middle">Unit</th>
                      <th colspan="4">Baru</th>
                      <th rowspan="2" class="bg-green align-middle">TOTAL</th>
                      <th colspan="4">Aktif</th>
                      <th rowspan="2" class="bg-green align-middle">TOTAL</th>
                    </tr>
                    <tr>
                      <th>Umum</th>
                      <th>Alumni</th>
                      <th>Karyawan</th>
                      <th>Yayasan</th>
                      <th>Umum</th>
                      <th>Alumni</th>
                      <th>Karyawan</th>
                      <th>Yayasan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!units.length">
                      <th colspan="10" class="text-center">No Data</th>
                    </tr>
                    <tr v-for="unit in units" :key="unit.id">
                      <td class="text-center">{{ unit.name }}</td>
                      <td class="text-center">{{ totals.kategori[1][gel.name][unit.name] }}</td>
                      <td class="text-center">{{ totals.kategori[2][gel.name][unit.name] }}</td>
                      <td class="text-center">{{ totals.kategori[3][gel.name][unit.name] }}</td>
                      <td class="text-center">{{ totals.kategori[4][gel.name][unit.name] }}</td>
                      <th class="bg-green">{{ totals.kategori[1][gel.name][unit.name] + totals.kategori[2][gel.name][unit.name] + totals.kategori[3][gel.name][unit.name] + totals.kategori[4][gel.name][unit.name] }}</th>
                      <td class="text-center">{{ totals.kategori[5][gel.name][unit.name] }}</td>
                      <td class="text-center">{{ totals.kategori[6][gel.name][unit.name] }}</td>
                      <td class="text-center">{{ totals.kategori[7][gel.name][unit.name] }}</td>
                      <td class="text-center">{{ totals.kategori[8][gel.name][unit.name] }}</td>
                      <th class="bg-green">{{ totals.kategori[5][gel.name][unit.name] + totals.kategori[6][gel.name][unit.name] + totals.kategori[7][gel.name][unit.name] + totals.kategori[8][gel.name][unit.name] }}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gels: {},
      units: {},
      totals: {
        status: [],
        kategori: [],
      }
    };
  },
  
  props: ['userId'],

  mounted() {
    axios
      .get("../api/dashboard")
      .then((data) => {
        this.units = data.data.unit
        this.gels = data.data.gel
        this.totals.status = data.data.status
        this.totals.kategori = data.data.kategori
      })
      // .then(({ data }) => (this.units = data.unit));
  }
};
</script>
