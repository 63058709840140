<template>
<div class="container">
    <div class="row justify-content-center">
        <div class="mt-2" v-bind:class="level === false ? 'col-md-10' : 'col-md-12' ">
            <div class="card card-primary card-outline">
                <div class="card-header bg-primary">
                    <h3 class="card-title">
                        <i class="fas fa-user-edit"></i>
                        Form Pendaftaran
                    </h3>
                    <div class="card-tools">
                        <a v-if="level === false" href="../ppdb" type="button" class="btn bg-danger btn-sm">
                            <i class="fas fa-times"></i>
                        </a>
                        <a v-else @click="$router.go(-1)" type="button" class="btn bg-danger btn-sm">
                            <i class="fas fa-times"></i>
                        </a>
                    </div>
                </div>
                <div class="card-body">
                    <form-wizard
                        @on-complete="onComplete"
                        ref="wizard"
                        title=""
                        subtitle=""
                        stepSize="xs"
                        shape="circle"
                        color="#20a0ff"
                        error-color="#ff4949">
                        <tab-content title="Data Pribadi" icon="fas fa-user">
                                <!-- <div class="form-group row">
                                    <label class="col-md-3 col-form-label">Jenjang Pendidikan</label>
                                    <div class="col-md-4">
                                        <input
                                        type="text"
                                        class="form-control"
                                        disabled="disabled"
                                        :value="unit.name"
                                        >
                                    </div>
                                    <label class="col-md-2 col-form-label">Kelas Tujuan</label>
                                    <div class="col-md-2">
                                        <select :disabled="form.ck_id == 2" v-model="form.kelas_tujuan" name="kelas_tujuan" class="form-control" id="kelas_tujuan">
                                            <option
                                                v-for="kls in kelass"
                                                :key="kls.id"
                                                v-bind:value="kls.id"
                                            >{{ kls.name }}</option>
                                        </select>
                                        <has-error :form="form" field="kelas_tujuan"></has-error>
                                    </div>
                                </div> -->
                                <hr>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">Nama Lengkap</label>
                                    <div class="col-md-4">
                                        <input
                                            v-model="form.name"
                                            type="text"
                                            name="name"
                                            class="form-control"
                                            :disabled="form.ck_id == 2"
                                            :class="{ 'is-invalid':form.errors.has('name') }"
                                            id="name"
                                            placeholder="Nama Lengkap"
                                        >
                                        <has-error :form="form" field="name"></has-error>
                                    </div>
                                    <label class="col-md-2 col-form-label">Panggilan</label>
                                    <div class="col-md-3">
                                        <input
                                            v-model="form.panggilan"
                                            type="text"
                                            name="panggilan"
                                            class="form-control"
                                            :class="{ 'is-invalid':form.errors.has('panggilan') }"
                                            id="panggilan"
                                            placeholder="Nama Panggilan"
                                        >
                                        <has-error :form="form" field="panggilan"></has-error>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">Tempat Lahir</label>
                                    <div class="col-md-4">
                                        <input
                                            v-model="form.tempat_lahir"
                                            type="text"
                                            name="tempat_lahir"
                                            class="form-control"
                                            :class="{ 'is-invalid':form.errors.has('tempat_lahir') }"
                                            id="tempat_lahir"
                                            placeholder="Tempat Lahir"
                                        >
                                        <has-error :form="form" field="tempat_lahir"></has-error>
                                    </div>
                                    <label class="col-md-2 col-form-label">Tanggal Lahir</label>
                                    <div class="col-md-3">
                                        <VueDatePicker
                                            v-model="form.tgl_lahir"
                                            format="DD MMMM YYYY"
                                            format-output="YYYY/MM/DD"
                                            placeholder="Tgl/Bln/Tahun"
                                            v-bind:max-date="minimum_age"
                                            :locale='{ lang: {
                                                "name": "id",
                                                "weekdays": ["Ahad","Senin","Selasa","Rabu","Kamis","Jum`at","Sabtu"],
                                                "weekdaysShort": ["Ahd","Sen","Sel","Rab","Kam","Jum","Sab"],
                                                "weekStart": 1,
                                                "months": ["Januari","Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"],
                                                "monthsShort": ["Jan","Feb","Mar","Apr","Mei","Jun","Jul","Ags","Sep","Okt","Nov","Des"],
                                                "formats": {
                                                    "LT": "HH:mm",
                                                    "LTS": "HH:mm:ss",
                                                    "L": "DD/MM/YYYY",
                                                    "LL": "D MMMM YYYY",
                                                    "LLL": "D MMMM YYYY HH:mm",
                                                    "LLLL": "dddd D MMMM YYYY HH:mm"
                                                }
                                                } }'
                                            class="form-control"
                                        no-header/>
                                        <has-error :form="form" field="tgl_lahir"></has-error>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">Jenis Kelamin</label>
                                    <div class="col-md-3">
                                        <select v-model="form.jk" name="jk" class="form-control" id="jk">
                                            <option value=1>Laki-Laki</option>
                                            <option value=2>Perempuan</option>
                                        </select>
                                        <has-error :form="form" field="jk"></has-error>
                                    </div>
                                    <label class="col-md-2 offset-md-1 col-form-label">Agama</label>
                                    <div class="col-md-2">
                                        <select v-model="form.agama" name="agama" class="form-control" id="agama">
                                            <option
                                                v-for="agama in agamas"
                                                :key="agama.id"
                                                v-bind:value="agama.id"
                                            >{{ agama.name }}</option>
                                        </select>
                                        <has-error :form="form" field="agama"></has-error>
                                    </div>
                                </div>
                                <div class="form-group row" v-show="unit_ck !== 'TK' && unit_ck !== 'SD'">
                                    <label class="col-md-3 col-form-label">NISN</label>
                                    <div class="col-md-4">
                                        <input
                                        v-model="form.nisn"
                                        type="text"
                                        name="nisn"
                                        class="form-control"
                                        :class="{ 'is-invalid':form.errors.has('nisn') }"
                                        id="nisn"
                                        placeholder="NISN"
                                        >
                                        <has-error :form="form" field="nisn"></has-error>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">Alamat Tempat Tinggal</label>
                                    <div class="col-md-9">
                                        <input
                                        v-model="form.alamat"
                                        type="text"
                                        name="alamat"
                                        class="form-control"
                                        :class="{ 'is-invalid':form.errors.has('alamat') }"
                                        id="alamat"
                                        placeholder="Alamat Tempat Tinggal"
                                        >
                                        <has-error :form="form" field="alamat"></has-error>
                                    </div>
                                </div>
                        </tab-content>
                        <tab-content title="Data Orang Tua" icon="fas fa-users">
                            <div class="card-group">
                                <div class="card">
                                <div class="card-header">
                                    <h3 class="text-center">Data Ayah</h3>
                                </div>
                                <div class="card-body">
                                <div class="form-group row">
                                    <label class="col-md-5 col-form-label">Nama Lengkap</label>
                                    <div class="col-md-7">
                                        <input
                                        v-model="form.ayah_nama"
                                        type="text"
                                        name="ayah_nama"
                                        class="form-control"
                                        :class="{ 'is-invalid':form.errors.has('ayah_nama') }"
                                        id="ayah_nama"
                                        placeholder="Nama Ayah"
                                        >
                                        <has-error :form="form" field="ayah_nama"></has-error>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-5 col-form-label">No. Ponsel</label>
                                    <div class="col-md-7">
                                        <input
                                        v-model="form.ayah_hp"
                                        type="text"
                                        name="ayah_hp"
                                        class="form-control"
                                        :class="{ 'is-invalid':form.errors.has('ayah_hp') }"
                                        id="ayah_hp"
                                        >
                                        <has-error :form="form" field="ayah_hp"></has-error>
                                    </div>
                                </div>
                                </div>
                                </div>
                                <div class="card">
                                <div class="card-header">
                                    <h3 class="text-center">Data Ibu</h3>
                                </div>
                                <div class="card-body">
                                <div class="form-group row">
                                    <label class="col-md-5 col-form-label">Nama Lengkap</label>
                                    <div class="col-md-7">
                                        <input
                                        v-model="form.ibu_nama"
                                        type="text"
                                        name="ibu_nama"
                                        class="form-control"
                                        :class="{ 'is-invalid':form.errors.has('ibu_nama') }"
                                        id="ibu_nama"
                                        placeholder="Nama Ibu"
                                        >
                                        <has-error :form="form" field="ibu_nama"></has-error>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-5 col-form-label">No. Ponsel</label>
                                    <div class="col-md-7">
                                        <input
                                        v-model="form.ibu_hp"
                                        type="text"
                                        name="ibu_hp"
                                        class="form-control"
                                        :class="{ 'is-invalid':form.errors.has('ibu_hp') }"
                                        id="ibu_hp"
                                        >
                                        <has-error :form="form" field="ibu_hp"></has-error>
                                    </div>
                                </div>
                                </div>
                                </div>
                            </div>
                        </tab-content>
                        <tab-content title="Data Asal Sekolah" icon="fas fa-school">
                            <div v-if="form.asal_nf == 0">
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">Nama Sekolah</label>
                                    <div class="col-md-5">
                                        <input
                                        v-model="form.asal_sekolah"
                                        type="text"
                                        name="asal_sekolah"
                                        class="form-control"
                                        :class="{ 'is-invalid':form.errors.has('asal_sekolah') }"
                                        id="asal_sekolah"
                                        placeholder="Nama Asal Sekolah"
                                        >
                                        <has-error :form="form" field="asal_sekolah"></has-error>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-md-3 col-form-label">Alamat Asal Sekolah</label>
                                    <div class="col-md-9">
                                        <input
                                        v-model="form.asal_alamat_sekolah"
                                        type="text"
                                        name="asal_alamat_sekolah"
                                        class="form-control"
                                        :class="{ 'is-invalid':form.errors.has('asal_alamat_sekolah') }"
                                        id="asal_alamat_sekolah"
                                        placeholder="Alamat Asal Sekolah"
                                        >
                                        <has-error :form="form" field="asal_alamat_sekolah"></has-error>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <h2>{{ form.asal_sekolah }}</h2>
                                <p>Alamat Asal Sekolah :</p>
                                <p>{{ form.asal_alamat_sekolah }} Kelurahan Tugu </p>
                                <p>Kec. Cimanggis Kota Depok - Jawa Barat</p>
                            </div>
                        </tab-content>
                        <button type="primary" class="btn btn-warning" slot="prev">Back</button>
                        <button type="primary" class="btn btn-primary" slot="next" v-on:click="cekform()">Next</button>
                        <button type="primary" class="btn btn-success" slot="finish"
                        >Data yang telah Saya isi adalah Benar </button>
                        <div v-if="salahs.length" class="alert alert-danger alert-dismissible">
                            <h5><i class="icon fas fa-ban"></i> Alert!</h5>
                            <ul>
                                <li v-for="salah in salahs" :key="salah">{{ salah }}</li>
                            </ul>
                        </div>

                    </form-wizard>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { constants } from 'crypto';
    export default {
        data() {
            return {
                stepIndex:0,
                level: false,
                unit: {},
                unit_ck: "",
                minimum_age: "",
                Verror: {},
                agamas: {},
                infos: {},
                provinsi: {},
                kotas: {},
                camats: {},
                lurahs: {},
                kotasekolah: {},
                camatsekolah: {},
                lurahsekolah: {},
                pendidikan: {},
                pekerjaan: {},
                gajis: {},
                kelass: {},
                calon: [],
                salahs: [],
                form: new Form({
                    id: "",
                    nisn: "",
                    nik: "",
                    name: "",
                    panggilan: "",
                    jk: "",
                    kelas_tujuan: "",
                    photo: "",
                    tempat_lahir: "",
                    tgl_lahir: '1980-10-07',
                    agama: "",
                    info: "",
                    status: "",
                    user_id: "",
                    alamat: "",
                    rt: "",
                    rw: "",
                    kodepos: "",
                    provinsi: "",
                    kota: "",
                    kecamatan: "",
                    kelurahan: "",
                    phone: "",
                    ayah_nama: "",
                    ayah_pendidikan: "",
                    ayah_pekerjaan: "",
                    ayah_penghasilan: "",
                    ayah_hp: "",
                    ayah_email: "",
                    ibu_nama: "",
                    ibu_pendidikan: "",
                    ibu_pekerjaan: "",
                    ibu_penghasilan: "",
                    ibu_hp: "",
                    ibu_email: "",
                    asal_nf:"",
                    asal_sekolah: "",
                    asal_alamat_sekolah: "",
                })
            }
        },

        methods: {
            cekform() {
                var aI = this.$refs.wizard.activeTabIndex
                this.salahs = []
                switch(aI) {
                    case 0:
                        if(this.form.kelas_tujuan == ""){this.salahs.push('Kelas Tujuan harus diisi')}
                        if(this.form.name == ""){this.salahs.push('Nama Lengkap Calon Siswa harus diisi')}
                        if(this.form.panggilan == ""){this.salahs.push('Nama Panggilan Calon Siswa harus diisi')}
                        if(this.form.tempat_lahir == ""){this.salahs.push('Tempat Kelahiran Calon Siswa harus diisi')}
                        if(this.form.jk == ""){this.salahs.push('Jenis Kelamin Calon Siswa harus dipilih')}
                        if(this.form.alamat == ""){this.salahs.push('Alamat Tempat Tinggal Calon Siswa harus diisi')}
                        if(this.salahs.length) {
                            this.$refs.wizard.changeTab(0,aI-1)
                        } else {
                            this.salahs = []
                            this.$refs.wizard.nextTab
                        }
                        break

                    case 2:
                        if(this.form.ayah_nama == ""){this.salahs.push('Nama Ayah Calon Siswa harus diisi')}
                        if(this.form.ayah_hp == ""){this.salahs.push('Nomor Ponsel Ayah harus dipilih')}
                        if(this.form.ibu_nama == ""){this.salahs.push('Nama Ibu Calon Siswa harus diisi')}
                        if(this.form.ibu_hp == ""){this.salahs.push('Nomor Ponsel Ibu harus dipilih')}
                        if(this.salahs.length) {
                            this.$refs.wizard.changeTab(0,aI-1)
                        } else {
                            this.salahs = []
                            this.$refs.wizard.nextTab
                        }
                        break

                    default:
                        this.salahs = []
                        this.$refs.wizard.nextTab
                }
            },

            onComplete: function() {
                this.$Progress.start();
                this.form
                    .put("../api/calons/"+this.form.id)
                    .then(() => {
                        Toast.fire({
                            type: "success",
                            title: "Edit Data Calon Siswa Berhasil"
                        });
                        this.$router.push('../cpd/0')
                        this.$Progress.finish()
                    })
                    .catch((error) => {
                        Swal.fire({ 
                            title: 'Error!',
                            type: 'error',
                            html: '<h3 class="yellow bg-red">'+JSON.stringify(this.form.errors.errors)+'</h3>',
                        })
                        this.$Progress.fail()
                    });
            },

        },

        mounted() {
            var levelnya = this.$route.path
            var pecah = levelnya.split('/')
            if(pecah[1] === 'editcalons'){
                this.level = true
            }

            axios
                .get("../api/calons/"+this.$route.params.id)
                .then(( data ) => {
                    this.form.reset();
                    this.form.fill(data.data.calon)
                    this.$refs.wizard.activateAll()
                    axios
                        .get("../api/gelombangs/" + data.data.calon.gel_id)
                        .then(( gel ) => {
                            this.unit = gel.data.unitnya
                            this.unit_ck = gel.data.unitnya.catnya.name
                            this.minimum_age = gel.data.minimum_age
                            axios
                                .get("../api/kelasnya/" + gel.data.unitnya.id)
                                .then(( kelas ) => {(this.kelass = kelas.data)
                    })

                    })
                })

            axios
                .get("../api/agama")
                .then(({ data }) => (this.agamas = data));

        }
    }
</script>
